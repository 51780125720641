import { Routes } from '@angular/router';
const ɵ0 = () => import("./modules/index/index.module.ngfactory").then(m => m.IndexModuleNgFactory), ɵ1 = () => import("./modules/login/login.module.ngfactory").then(m => m.LoginModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'login',
        loadChildren: ɵ1
    },
    { path: '**', redirectTo: '/app/expenses' }
];
export class AppModule {
}
export { ɵ0, ɵ1 };
