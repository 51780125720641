import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import {
  Router,
  RouterEvent,
  RouteConfigLoadStart,
  RouteConfigLoadEnd
} from '@angular/router';

@Component({
  selector: 'c3po-app',
  template: `
    <div *ngIf="loading" class="index-loading-wrapper">
      <div class="index-loader"></div>
    </div>
    <router-outlet> </router-outlet>
  `,
  styles: [``]
})
export class AppComponent {
  loading = true;

  constructor(private router: Router) {
    console.log('[AppComponent]', environment);
    let asyncLoadCount = 0;
    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof RouteConfigLoadStart) {
        asyncLoadCount++;
      } else if (event instanceof RouteConfigLoadEnd) {
        asyncLoadCount--;
      }

      this.loading = asyncLoadCount > 0;
    });
  }
}
